.App {
  text-align: center;
  min-height: 100vh;
}

.App-logo {
  height: 25vmin;
  pointer-events: none;
  margin: 50px 20px 45px 20px;
  padding: 5px;
}

.header-icon {
  height: 1.5em;
  margin-right: 15px;
  margin-bottom: 8px;
}

.icon {
  height: 1em;
  margin-right: 10px;
  margin-bottom: 2px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.header {
  margin-bottom: 10px;
  padding-right: 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.yellow {
  background-color: #ee780a !important;
  margin: 6px;

}

.purple {
  background-color: #371f56 !important;
  margin: 6px 6px 0 6px;

}


.pink {
  background-color: #e460a2c2 !important;
  margin: 6px;
}

.red {
  background-color: #cb2c39c9 !important;
  margin: 6px;
}

.violet {
  background-color: #5253a3de !important;
  margin: 6px;
}

.blueFacebook {
  background-color: #4267b2b4 !important;
  margin: 6px;
}

.blueTwitter {
  background-color: #00abeea9 !important;
  padding: 0px;
  margin: 6px;
  max-width: 55px;
}

.grey {
  background-color: #6c6c6c !important;
  margin: 6px;
}

.event_button {
  background-color: #2e5ebd !important;
  margin: 6px;
}

.event_box{
  background-color: #fff093 !important;
  border-radius: 10px;
  color: black;
  padding: 10px;
  margin-bottom: 20px;
  margin-inline: 10px;
}

.flex{
  display: flex;
  align-items: center;
}

.event_text{
  margin-block: auto;
  font-size: 1.2rem !important;
}

.footer {
  flex-shrink: 0;
  text-align: center;
  color: #757585;

}

.footer-sm {
  flex-shrink: 0;
  text-align: center;
  font-size: small;
  color: #606069;

}



/* Starry Background with Much Slower Left-to-Right Movement and Enhanced Twinkling */
@keyframes twinkling {
  0%, 100% { 
    opacity: 1; 
    transform: scale(1);
  }
  50% { 
    opacity: 0.2; 
    transform: scale(3); /* Grow and shrink to simulate twinkling */
  }
}

@keyframes move-stars {
  0% {
    transform: translateX(-100vw);
  }
  100% {
    transform: translateX(100vw); /* Move stars from left to right */
  }
}

.stars {
  position: absolute;
  width: 100%;
  height: 100vh;
  background: black;
  overflow: hidden;
  z-index: -1;
}

.star {
  position: absolute;
  width: 3px;
  height: 3px;
  background: white;
  border-radius: 50%;
  animation: twinkling 5s infinite, move-stars 100s linear infinite; /* Much slower movement */
  opacity: 0.8;
}